/**!
  Visualization Classes for debugging the vertical-collection
  */
.vertical-collection-visual-debugger {
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  background: rgba(50, 50, 50, 1);
  width: 125px;
}

.vertical-collection-visual-debugger .vc_visualization-container {
  transform: scale(0.25);
  left: 0;
  position: relative;
}


.vertical-collection-visual-debugger .vc_visualization-screen {
  position: absolute;
  background: transparent;
  box-sizing: content-box;
  border-top: 2px dashed yellow;
  border-bottom: 2px dashed yellow;
  width: 500px;
}

.vertical-collection-visual-debugger .vc_visualization-scroll-container {
  position: absolute;
  width: 500px;
  background: rgba(100, 230, 100, 0.65);
}

.vertical-collection-visual-debugger .vc_visualization-item-container {
  position: absolute;
  width: 500px;
  background: rgba(255, 255, 255, .15);
}

.vertical-collection-visual-debugger .vc_visualization-virtual-component {
  box-sizing: border-box;
  background: rgba(230, 100, 230, 0.6);
  border: 1px dotted #bbb;
  border-top: 0px;
  color: #fff;
  text-align: center;
  font-size: 2.5em;
  width: 250px;
}

.vertical-collection-visual-debugger .vc_visualization-virtual-component:first-of-type {
  border-top: 1px dotted #bbb;
}

.vertical-collection-visual-debugger .vc_visualization-virtual-component.culled {
  background: transparent;
}

/* This is a compiled file, you should be editing the file in the templates directory */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #ee3148;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.occluded-content {
  display: block;
  position: relative;
  width: 100%;

  /* prevents margin overflow on item container */
  min-height: 0.01px;

  /* hides text visually while still being readable by screen readers */
  color: rgba(0,0,0,0);
}

table .occluded-content,
tbody .occluded-content,
thead .occluded-content,
tfoot .occluded-content {
  display: table-row;
  position: relative;
  width: 100%;
}

ul .occluded-content,
ol .occluded-content {
  display: list-item;
  position: relative;
  width: 100%;
  list-style-type: none;
  height: 0;
}

:root {
  --ecn-container-position: 10px;
  --ecn-container-width: 80%;
  --ecn-container-max-with: 400px;

  --ecn-icon-width: 30px;
  --ecn-icon-position: 10px;
  --ecn-icon-color: rgba(255, 255, 255, 0.74);
  --ecn-icon-lighten-background: rgba(255, 255, 255, 0.2);
  --ecn-countdown-lighten-background: rgba(255, 255, 255, 0.4);
  --ecn-notification-max-height: 800px;
  --ecn-notification-border-radius: 3px;

  /* Colours */
  --ecn-green: #64ce83;
  --ecn-blue: #3ea2ff;
  --ecn-orange: #ff7f48;
  --ecn-red: #e74c3c;

  /* Spacing */
  --ecn-spacing-1: .5rem;
  --ecn-spacing-2: 1rem;
}

/* Base */

.ember-cli-notifications-notification__container {
  position: fixed;
  margin: 0 auto;
  width: 80%;
  width: var(--ecn-container-width);
  max-width: 400px;
  max-width: var(--ecn-container-max-with);
}

/* Position */

.ember-cli-notifications-notification__container--top {
  top: 10px;
  top: var(--ecn-container-position);
  right: 0;
  left: 0;
}

.ember-cli-notifications-notification__container--top-left {
  top: 10px;
  top: var(--ecn-container-position);
  right: auto;
  left: 10px;
  left: var(--ecn-container-position);
}

.ember-cli-notifications-notification__container--top-right {
  top: 10px;
  top: var(--ecn-container-position);
  right: 10px;
  right: var(--ecn-container-position);
  left: auto;
}

.ember-cli-notifications-notification__container--bottom {
  right: 0;
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: 0;
}

.ember-cli-notifications-notification__container--bottom-left {
  right: auto;
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: 10px;
  left: var(--ecn-container-position);
}

.ember-cli-notifications-notification__container--bottom-right {
  right: 10px;
  right: var(--ecn-container-position);
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: auto;
}

/* Values */
.ember-cli-notifications-notification__container .c-notification {
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  border-radius: var(--ecn-notification-border-radius);
  border-bottom: 1rem;
  color: white;
  max-height: var(--ecn-notification-max-height);
  animation: notification-hide 250ms cubic-bezier(.33859, -.42, 1, -.22), notification-shrink 250ms 250ms cubic-bezier(.5, 0, 0, 1);
  animation-fill-mode: forwards;
  margin-bottom: var(--ecn-spacing-2);
}
.ember-cli-notifications-notification__container .c-notification--clickable {
  cursor: pointer;
}
.ember-cli-notifications-notification__container .c-notification--in {
  animation: notification-show 180ms cubic-bezier(.175, .885, .32, 1.27499);
}
.ember-cli-notifications-notification__container .c-notification__content {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  justify-content: space-between;
  padding: var(--ecn-spacing-1) var(--ecn-spacing-2);
  word-break: break-word;
}
.ember-cli-notifications-notification__container .c-notification__content a {
  color: #fff;
  text-decoration: underline;
}
.ember-cli-notifications-notification__container .c-notification__icon {
  padding: var(--ecn-spacing-1) 0;
  text-align: center;
  flex: none;
  background-color: var(--ecn-icon-lighten-background);
  width: var(--ecn-icon-width);
  color: var(--ecn-icon-color);
}
.ember-cli-notifications-notification__container .c-notification__svg {
  width: 16px;
  height: 16px;
  vertical-align: text-top;
}
.ember-cli-notifications-notification__container .c-notification__close {
  margin-left: var(--ecn-spacing-2);
  align-self: flex-start;
  opacity: .74;
  cursor: pointer;
}
.ember-cli-notifications-notification__container .c-notification__close:hover,
.ember-cli-notifications-notification__container .c-notification__close:focus {
  opacity: 1;
}
.ember-cli-notifications-notification__container .c-notification__countdown {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--ecn-countdown-lighten-background);
  width: 0;
  height: 4px;
  animation: notification-countdown linear 1;
}
/* Theme */
.ember-cli-notifications-notification__container .c-notification--info {
  background-color: var(--ecn-blue);
}
.ember-cli-notifications-notification__container .c-notification--success {
  background-color: var(--ecn-green);
}
.ember-cli-notifications-notification__container .c-notification--warning {
  background-color: var(--ecn-orange);
}
.ember-cli-notifications-notification__container .c-notification--error {
  background-color: var(--ecn-red);
}
/* Keyframes */
@keyframes notification-show {
  0% {
    opacity: 0;
    transform: perspective(450px) translate(0, -30px) rotateX(90deg);
  }

  100% {
    opacity: 1;
    transform: perspective(450px) translate(0, 0) rotateX(0deg);
  }
}
@keyframes notification-shrink {
  0% {
    opacity: 0;
    max-height: var(--ecn-notification-max-height);
    transform: scale(.8);
  }

  100% {
    opacity: 0;
    max-height: 0;
    transform: scale(.8);
  }
}
@keyframes notification-hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.8);
  }
}
@keyframes notification-countdown {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

:root {
  --ecn-container-position: 10px;
  --ecn-container-width: 80%;
  --ecn-container-max-with: 400px;

  --ecn-icon-width: 30px;
  --ecn-icon-position: 10px;
  --ecn-icon-color: rgba(255, 255, 255, 0.74);
  --ecn-icon-lighten-background: rgba(255, 255, 255, 0.2);
  --ecn-countdown-lighten-background: rgba(255, 255, 255, 0.4);
  --ecn-notification-max-height: 800px;
  --ecn-notification-border-radius: 3px;

  /* Colours */
  --ecn-green: #64ce83;
  --ecn-blue: #3ea2ff;
  --ecn-orange: #ff7f48;
  --ecn-red: #e74c3c;

  /* Spacing */
  --ecn-spacing-1: .5rem;
  --ecn-spacing-2: 1rem;
}

/* Base */

.ember-cli-notifications-notification__container {
  position: fixed;
  margin: 0 auto;
  width: 80%;
  width: var(--ecn-container-width);
  max-width: 400px;
  max-width: var(--ecn-container-max-with);
}

/* Position */

.ember-cli-notifications-notification__container--top {
  top: 10px;
  top: var(--ecn-container-position);
  right: 0;
  left: 0;
}

.ember-cli-notifications-notification__container--top-left {
  top: 10px;
  top: var(--ecn-container-position);
  right: auto;
  left: 10px;
  left: var(--ecn-container-position);
}

.ember-cli-notifications-notification__container--top-right {
  top: 10px;
  top: var(--ecn-container-position);
  right: 10px;
  right: var(--ecn-container-position);
  left: auto;
}

.ember-cli-notifications-notification__container--bottom {
  right: 0;
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: 0;
}

.ember-cli-notifications-notification__container--bottom-left {
  right: auto;
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: 10px;
  left: var(--ecn-container-position);
}

.ember-cli-notifications-notification__container--bottom-right {
  right: 10px;
  right: var(--ecn-container-position);
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: auto;
}

/* Values */

.ember-cli-notifications-notification__container .c-notification {
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  border-radius: var(--ecn-notification-border-radius);
  border-bottom: 1rem;
  color: white;
  max-height: 800px;
  max-height: var(--ecn-notification-max-height);
  animation: notification-hide 250ms cubic-bezier(.33859, -.42, 1, -.22), notification-shrink 250ms 250ms cubic-bezier(.5, 0, 0, 1);
  animation-fill-mode: forwards;
  margin-bottom: 1rem;
  margin-bottom: var(--ecn-spacing-2);
}

.ember-cli-notifications-notification__container .c-notification--clickable {
  cursor: pointer;
}

.ember-cli-notifications-notification__container .c-notification--in {
  animation: notification-show 180ms cubic-bezier(.175, .885, .32, 1.27499);
}

.ember-cli-notifications-notification__container .c-notification__content {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  justify-content: space-between;
  padding: .5rem 1rem;
  padding: var(--ecn-spacing-1) var(--ecn-spacing-2);
  word-break: break-word;
}

.ember-cli-notifications-notification__container .c-notification__content a {
  color: #fff;
  text-decoration: underline;
}

.ember-cli-notifications-notification__container .c-notification__icon {
  padding: .5rem 0;
  padding: var(--ecn-spacing-1) 0;
  text-align: center;
  flex: none;
  background-color: rgba(255, 255, 255, 0.2);
  background-color: var(--ecn-icon-lighten-background);
  width: 30px;
  width: var(--ecn-icon-width);
  color: rgba(255, 255, 255, 0.74);
  color: var(--ecn-icon-color);
}

.ember-cli-notifications-notification__container .c-notification__svg {
  width: 16px;
  height: 16px;
  vertical-align: text-top;
}

.ember-cli-notifications-notification__container .c-notification__close {
  margin-left: 1rem;
  margin-left: var(--ecn-spacing-2);
  align-self: flex-start;
  opacity: .74;
  cursor: pointer;
}

.ember-cli-notifications-notification__container .c-notification__close:hover,
.ember-cli-notifications-notification__container .c-notification__close:focus {
  opacity: 1;
}

.ember-cli-notifications-notification__container .c-notification__countdown {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  background-color: var(--ecn-countdown-lighten-background);
  width: 0;
  height: 4px;
  animation: notification-countdown linear 1;
}

/* Theme */

.ember-cli-notifications-notification__container .c-notification--info {
  background-color: #3ea2ff;
  background-color: var(--ecn-blue);
}

.ember-cli-notifications-notification__container .c-notification--success {
  background-color: #64ce83;
  background-color: var(--ecn-green);
}

.ember-cli-notifications-notification__container .c-notification--warning {
  background-color: #ff7f48;
  background-color: var(--ecn-orange);
}

.ember-cli-notifications-notification__container .c-notification--error {
  background-color: #e74c3c;
  background-color: var(--ecn-red);
}

/* Keyframes */

@keyframes notification-show {
  0% {
    opacity: 0;
    transform: perspective(450px) translate(0, -30px) rotateX(90deg);
  }

  100% {
    opacity: 1;
    transform: perspective(450px) translate(0, 0) rotateX(0deg);
  }
}

@keyframes notification-shrink {
  0% {
    opacity: 0;
    max-height: 800px;
    max-height: var(--ecn-notification-max-height);
    transform: scale(.8);
  }

  100% {
    opacity: 0;
    max-height: 0;
    transform: scale(.8);
  }
}

@keyframes notification-hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes notification-countdown {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.ember-table {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.ember-table .ember-table-overflow {
  overflow: auto;
  max-height: 100%;
  max-width: 100%;
}

.ember-table table {
  border-spacing: 0;
  table-layout: fixed;
  box-sizing: border-box;
}

.ember-table td,
.ember-table th {
  box-sizing: border-box;
}

.ember-table td.is-fixed-left,
.ember-table td.is-fixed-right,
.ember-table th.is-fixed-left,
.ember-table th.is-fixed-right {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.ember-table td.ember-table__text-align-left,
.ember-table th.ember-table__text-align-left {
  text-align: left;
}

.ember-table td.ember-table__text-align-center,
.ember-table th.ember-table__text-align-center {
  text-align: center;
}

.ember-table td.ember-table__text-align-right,
.ember-table th.ember-table__text-align-right {
  text-align: right;
}

.ember-table th {
  z-index: 2;
}

.ember-table th:not(.is-fixed-right) .et-header-resize-area {
  right: 0;
}

.ember-table th.is-fixed-right .et-header-resize-area {
  left: 0;
}

.ember-table td.is-fixed-left,
.ember-table td.is-fixed-right {
  z-index: 3;
}

.ember-table th.is-fixed-left,
.ember-table th.is-fixed-right {
  z-index: 4;
}

.ember-table th.is-sortable {
  cursor: pointer;
}

.ember-table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  box-sizing: border-box;
}

.ember-table tbody {
  box-sizing: border-box;
}

.ember-table tfoot {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 2;
  box-sizing: border-box;
}

.ember-table .scroll-indicator {
  position: absolute;
  z-index: 5;
}

.ember-table .scroll-indicator__left,
.ember-table .scroll-indicator__right {
  top: 0;
  width: 8px;
  height: 100%;
}

.ember-table .scroll-indicator__left {
  background: linear-gradient(to right, rgba(168, 168, 168, 0.4), rgba(168, 168, 168, 0));
}

.ember-table .scroll-indicator__right {
  right: 0;
  background: linear-gradient(to left, rgba(168, 168, 168, 0.4), rgba(168, 168, 168, 0));
}

.ember-table .scroll-indicator__top,
.ember-table .scroll-indicator__bottom {
  left: 0;
  width: 100%;
  height: 8px;
}

.ember-table .scroll-indicator__top {
  top: 0;
  background: linear-gradient(to bottom, rgba(168, 168, 168, 0.4), rgba(168, 168, 168, 0));
}

.ember-table .scroll-indicator__bottom {
  bottom: 0;
  background: linear-gradient(to top, rgba(168, 168, 168, 0.4), rgba(168, 168, 168, 0));
}

.ember-table.et-unselectable {
  user-select: none;
}

.ember-table .et-header-resize-area {
  cursor: col-resize;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
}

.ember-table .et-speech-only {
  display: none !important;
}

@media speech {
  .ember-table .et-speech-only {
    display: block !important;
  }
}

.ember-table .ember-table-loading-more {
  display: inline-block;
}
